import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CollectionSlider from '@components/CollectionSlider'
import sanityClient from '@sanity/client'
import client from '@utils/sanityClient'
import useBreakpoint from '@utils/useBreakpoint'
import { imageWithAlt } from '@lib/fragments'

const Collection = (props) => {
	const { isMobile } = useBreakpoint()

	// States
	const [slides, setSlides] = useState([])

	// Latest Query
	useEffect(() => {
		//set filter 
		if (props.content.population === 'select' || props.content._type === 'productCollection') {
			if ((props.content.storyType === 'story') || (props.content._type === 'articleCollection') || (props.content._type === 'productCollection')) {
				setSlides(props.content.posts)
			} else {
				setSlides(props.content.profiles)
			}
		} else {
			let query = generateQuery(props.content)
			client.fetch(query).then(result => {
				if(result?.length){
					setSlides(result)
				}
			})
		}
	}, []) 


	const generateQuery = (content) => {

		const filter = props.content.categoryOrTag
		let query = '*['

		if (content._type === 'articleCollection') {
			query += '_type == "article"'
		} else if (content._type === 'storyCollection'){
			if (content.storyType === 'volunteerStory') {
				query += '_type == "volunteerStory"'
			} else {
				query += '_type == "story"'
			}
		} 
		// category or tag
		if (filter && filter._type === 'category') {
			query += `&& '${filter.slug.current}' in categories[]->slug.current  `
		} else if (filter && filter._type === 'tag'){
			query += `&& '${filter.slug.current}' in tags[]->slug.current  `
		}
		
		query += ']'

		// add order
		query += '| order(_createdAt desc)'

		query += `{
				_id,
				title,
				_type,
				header{
					...,
					image${imageWithAlt}
				},
				'headerImage': ${imageWithAlt},
				slug
			}`
		return query

	}

	return <CollectionSlider slides={slides} isMobile={isMobile} {...props}/>
}

Collection.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default Collection