import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, desktop } from '@styles/global'
import RichText from '@components/RichText'
import SlideDown from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import Section from '@components/Section'
import {BdrPlus, FillMinus, BdrCross} from '@components/Svg'

const QuestionBlock = ({ questionBlock, index, onChange, close, noNumber }) => {
	const [open, setOpen] = useState(false)
	const [hover, setHover] = useState(false)
	const [init, setInit] = useState(false)

	useEffect(() => {
		if (init) {
			onChange(open)
		}
		setInit(true)
	}, [open])

	useEffect(() => {
		if (close) {
			setOpen(false)
		}
	}, [close])

	return (
		<QuestionWrap open={open}>
			<QuestionGrid 
				onClick={() => setOpen(!open)} 
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<Index noNumber={noNumber}>{index + 1}</Index>
				<Question className='sub' noNumber={noNumber}>
					<RichText content={questionBlock.question} moduleStyles/>
				</Question>
				<Symbol>
					<Plus open={open}/>
					<Minus open={open}/>
				</Symbol>
			</QuestionGrid>
			<InnerGrid>
				<SlideDownAnswer noNumber={noNumber}>
					{open && 
						<Answer noNumber={noNumber}>
							<RichText content={questionBlock.answer} moduleStyles/>
						</Answer>
					}
				</SlideDownAnswer>
			</InnerGrid>
		</QuestionWrap>	
	)
}

const QuestionWrap = styled.div`
	border-bottom: 3px solid var(--beige);
	background-color: ${props => props.open ? 'var(--beige20)' : 'transparent'};
	${mobile}{
		background-color: transparent;
	}
`
const InnerGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 30px;
	${desktop}{
		grid-template-columns: repeat(12, 1fr);
	}
	${mobile}{
		grid-column-gap: 14px;
	}
`
const QuestionGrid = styled(InnerGrid)`
	cursor: pointer;
	:hover{
		background-color: var(--beige20);
	}
	min-height: 84px;
`
const Index = styled.h4`
	grid-row: 1;
	display: ${props => props.noNumber ? 'none' : 'block'};
	padding: var(--s) var(--xs);
	${desktop}{
		grid-column: 1/3;
	}
	${mobile}{
		padding: var(--s) 0 var(--xxs);
	}
`
const Question = styled.div`
	grid-column: ${props => props.noNumber ? '1/8' : '2/8'};
	grid-row: 1;
	padding: ${props => props.noNumber ? 'var(--s) var(--xs)' : 'var(--s) 0'} ;
	align-self: center;
	p {
		margin-bottom: 0px;
	}
	${desktop}{
		grid-column: ${props => props.noNumber ? '1/11' : '3/11'};
	}
	${mobile}{
		grid-column: ${props => props.noNumber ? '1/11' : '1/13'};
		grid-row: ${props => props.noNumber ? '1' : '2'};
		padding: ${props => props.noNumber ? '0' : '0 0 var(--s)'};
    display: ${props => props.noNumber ? 'flex' : 'block'};
    align-items: center;
	}
`
const Symbol = styled.div`
	grid-column: 8/9;
	text-align: right;
	position: relative;
	${desktop}{
		grid-column: 11/13;
	}
`
const Plus = styled(BdrPlus)`
	position: absolute;
	top: var(--s);
	right: var(--xs);
	width: 34px;
	height: 34px;
	transition: opacity 0.2s;
	opacity: ${props => props.open ? '0' : '1'};
	${mobile}{
		right: 0;
	}
`
const Minus = styled(FillMinus)`
	position: absolute;
	top: var(--s);
	right: var(--xs);
	width: 34px;
	height: 34px;
	opacity: 1;
	transition: opacity 0.2s;
	opacity: ${props => props.open ? '1' : '0'};
	${mobile}{
		right: 0;
	}
`
const SlideDownAnswer = styled(SlideDown)`
	grid-column: ${props => props.noNumber ? '1/8' : '2/8'};
	grid-row: 2;
	padding-left: ${props => props.noNumber ? 'var(--xs)' : '0'};
	${desktop}{
		grid-column: ${props => props.noNumber ? '1/11' : '3/11'};
	}
	${mobile}{
		grid-column: span 12;
	}
`
const Answer = styled.div`
	margin: var(--s) 0 var(--l);
`

const FaqBlock = ({ className, content }) => {

	const [anyOpen, setAnyOpen] = useState(0)
	const [allClosed, setAllClosed] = useState(false)

	const calculateAnyOpen = value => {
		if (value) {
			setAllClosed(false)
			setAnyOpen(anyOpen + 1)
		} else {
			setAnyOpen(anyOpen - 1)
		}
	}

	const closeAll = () => {
		setAllClosed(true)
	}

	return (
		<Wrap className={className} >
			{content.title && 
				<Head text={content.text}>
					<Heading>{content.title}</Heading>
					<CloseAll 
						className='h5'
						onClick={() => closeAll()}
						anyOpen={anyOpen}
					>
					Close All <Cross />
					</CloseAll>
				</Head>
			}
			{content.text &&
				<Section>
					<Text>
						<RichText content={content.text}/>
					</Text>
				</Section>
			}
			<Section>
				<Questions>
					{content?.questions?.map((question, i) => (
						<QuestionBlock 
							key={`qusetion${i}`} 
							questionBlock={question} 
							index={i} 
							onChange={value => calculateAnyOpen(value)}
							close={allClosed}
							noNumber={content.noNumbers}
						/>
					))}
				</Questions>
			</Section>
		</Wrap>
	)
}

const Wrap = styled.div`
	margin-top: var(--xxl);
	margin-bottom: var(--xxl);
`
const Head = styled(Section)`
	margin-bottom: ${props => props.text ? 'var(--m)' : 'var(--xl)'};
	> div {
		align-items: center;
	}
`
const Heading = styled.h3`
	grid-column: 1/7;
	${desktop}{
		grid-column: 1/9;
	}
`
const CloseAll = styled.button`
	grid-column: 7/9;
	text-align: right;
	padding-right: var(--xs);
	display: flex;	
	align-items: center;
	justify-self: end;
	transition: opacity 0.2s;
	opacity: ${props => props.anyOpen ? '1' : '0'};
	${desktop}{
		grid-column: 9/13;
	}
	${mobile}{
		padding-right: 0px;
	}
`
const Cross = styled(BdrCross)`
	width: 34px;
	height: 34px;
	margin-left: var(--xxs);
`
const Text = styled.div`
	margin-bottom: var(--xl);
	grid-column: span 8;
	${desktop}{
		grid-column: span 12;
	}
`
const Questions = styled.div`
	border-top: 3px solid var(--beige);
	grid-column: span 8;
	${desktop}{
		grid-column: span 12;
	}
`
FaqBlock.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}
QuestionBlock.propTypes = {
	questionBlock: PropTypes.object,
	index: PropTypes.number,
	onChange: PropTypes.func,
	close: PropTypes.bool,
}

export default FaqBlock