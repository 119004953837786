import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile } from '@styles/global'
import Section from '@components/Section'
import * as Flickity from 'react-flickity-component'
import Button from '@components/Button'
import Tile from '@components/Tile'

const CollectionSlider = ({ className, id, isMobile, slides, content }) => {

	const flickityOptions = {
		prevNextButtons: true,
		pageDots: false,
		adaptiveHeight: false,
		wrapAround: false,
		cellAlign: 'left',
		freeScroll: false,
		contain: true,
		draggable: true,
		arrowShape: 'M15.7192 50L56.25 91.879L48.3904 100L0 50L48.3904 0L56.25 8.12103L15.7192 50Z'
	}

	const contentTypes = {
		articleCollection: {
			plural: 'articles',
			singular: 'article'
		},
		storyCollection: {
			plural: (content.storyType === 'volunteerStory') ? 'Volunteer Profiles' : 'stories',
			singular: (content.storyType === 'volunteerStory') ? 'volunteerStory' : 'story'
		},
		productCollection: {
			plural: 'courses',
			singular: 'product'
		},
	}

	const [dragging, setDragging] = useState()

	let flkty

	useEffect(() => {
		if(flkty){
			flkty.on('dragStart', function(){
				setDragging(true)
			})
				
			flkty.on('dragEnd', function(){
				setDragging(false)
			})
		}
	}, [flkty])

	return (
		<Wrap 
			id={id}
			className={className}
			css={css`
				background-color: ${content?.background?.value ? content?.background?.value : 'transparent'};
			`}
		>
			<Container>
				<Head>
					<HeadLeft>
						<Eyebrow>{content?.eyebrow}</Eyebrow>
						<Heading>{content?.title}</Heading>
					</HeadLeft>
				</Head>
				<Flickity
					flickityRef={c => flkty = c}
					className={'carousel'} // default ''
					elementType={'div'} // default 'div'
					options={flickityOptions} // takes flickity options {}
					disableImagesLoaded={false} // default false
					// static // default false
					css={
						slides?.length > 3 || (isMobile && slides?.length > 1)? 
							css`${flickityStyles}`
							: 
							css`display: none;`
					}
				>
					{slides?.map(slide => (
						<Slide 
							key={slide.slug?.current} 
							content={slide}
							css={css`pointer-events: ${dragging ? 'none' : 'all'};`}
						/>
					))}
				</Flickity>
				<Grid 
					css={css`
						display: ${slides?.length > 3 ? 'none' : 'grid'};
						${mobile}{
							display: ${slides?.length > 1 ? 'none' : 'grid'};
						}
					`}
				>
					{slides?.map(slide => (
						<>
							<GridTile 
								key={slide.slug?.current} 
								content={slide}
							/>
						</>
					))}
				</Grid>
				<All>
					<Button 
						outline
						large
						link={{
							linkType: 'internal', 
							document:{
								_type: `${contentTypes[content?._type]?.singular}Index`,
							}
						}}
					>
						all {contentTypes[content?._type]?.plural}
					</Button>
				</All>
			</Container>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: var(--xxxxl) 0;
	${mobile}{
		padding: 64px 0;
	}
`
const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column: span 12;
	position: relative;
`
const Head = styled.div`
	grid-column: span 12;
	display: grid;
	grid-template-columns: 1fr max-content;
	margin-bottom: var(--l);
	${mobile}{
		grid-template-columns: 1fr;
	}
`
const HeadLeft = styled.div`
`
const Eyebrow = styled.h5`
	margin-bottom: var(--s);
`
const Heading = styled.h3`
	margin-bottom: var(--m);
`

const Grid = styled.div`
	grid-column: span 12;
	margin-bottom: var(--l);
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 30px;
	${mobile}{
		grid-column-gap: 14px;
	}
`
const Slide = styled(Tile)`
	width: calc(33.333% - 24.5px);
	margin-right: 30px;
	box-sizing: border-box;
	${mobile}{
		width: 100%;
	}
`
const GridTile = styled(Tile)`
	grid-column: span 4;
	${mobile}{
		grid-column: span 12;
	}
`
const flickityStyles = `
	grid-column: span 12;
	margin-bottom: var(--l);
	${mobile}{
		position: relative;
		margin-top: 40px;
	}
`

const All = styled.div`
	grid-column: span 12;
	text-align: center;
`

CollectionSlider.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	isMobile: PropTypes.bool, 
	slides: PropTypes.array, 
	content: PropTypes.object,
}

export default CollectionSlider
