import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { mobile, tablet, desktop } from '@styles/global'
import useModuleSettings from '@utils/useModuleSettings'
import RichText from '@components/RichText'
import { Eyebrow } from '@styles/global'
import Button from '@components/Button'

const Checklist = ({ className }) => {

	const { checklist } = useModuleSettings()

	return (
		<Wrap className={className}>
			<Col>	
				<Eyebrow>{checklist.eyebrow}</Eyebrow>
				<Headline>{checklist.headline}</Headline>
				<Text>
					<RichText content={checklist.text}/>
				</Text>
			</Col>
			<RightCol>
				<SmallHeading>Checklist for</SmallHeading>
				{checklist.checklists?.map(listItem => (
					<WideButton 
						bgColor='teal' 
						key={listItem.title}
						link={{
							linkType: 'internal', 
							document: listItem
						}}
					>
						{listItem.title}
					</WideButton>
				))}
			</RightCol>
		</Wrap>
	)
}

const Wrap = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	background-color: var(--bteal40);
	${mobile}{
		grid-template-columns: 1fr;
	}
`
const Col = styled.div`
	padding: 100px;
	${mobile}{
		padding: 64px 30px;
		text-align: center;
	}
`
const RightCol = styled(Col)`
	background-color: var(--bteal20);
	text-align: center;
	justify-items: center;
	${tablet}{
		padding: 100px 60px;
	}
	${mobile}{
		padding: 0 30px 64px;
		background-color: transparent;
	}
`
const SmallHeading = styled.h4`
	margin-bottom: var(--l);
`
const WideButton = styled(Button)`
	max-width: 420px;
	margin: 0 auto var(--s);
	display: flex;
	:last-of-type{
		margin-bottom: 0px;
	}
	${desktop}{
		min-width: 100%;
	}
`
const Headline = styled.h2`
	margin-bottom: var(--m);
`
const Text = styled.div`
	margin-bottom: var(--s);
`
Checklist.propTypes = {
	className: PropTypes.string
}

export default Checklist