import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '@styles/global'
import Section from '@components/Section'
import RichText from '@components/RichText'
import { Rarr } from '@components/Svg'
import Image from '@components/Image'
import Button from '@components/Button'

const Member = ({ member }) => {
	return (
		<MemberWrap>
			<HeadMobile>
				<Name>{member.name}</Name>
				<Role className='para'>{member.role}</Role>
			</HeadMobile>
			<ImageContainer>
				<MemberImage apectRatio={1} image={member.image}/>
				<LinkedInButton 
					icon='Rarr' 
					flex 
					link={{
						linkType: 'external', 
						url: member.link,
						blank: true
					}}>
					{member.linkText}
				</LinkedInButton>
			</ImageContainer>
			<Content>
				<HeadDesktop>
					<Name>{member.name}</Name>
					<Role className='para'>{member.role}</Role>
				</HeadDesktop>
				<MemberText>
					<RichText content={member.text}/>
				</MemberText>
				<LinkedInButtonMobile 
					icon='Rarr' 
					flex 
					link={{
						linkType: 'external', 
						url: member.link,
						blank: true
					}}>
					{member.linkText}
				</LinkedInButtonMobile>
			</Content>
		</MemberWrap>
	)
}

const MemberWrap = styled.div`
	background-color: var(--white);
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	grid-column-gap: 30px;
	padding: var(--l) 0;
  ${mobile}{
    grid-column-gap: 14px;
		padding: var(--m);
	}
`
const HeadMobile = styled.div`
	display: none;
	${mobile}{
		display: block;
		grid-column: span 10;;
	}
`
const HeadDesktop = styled.div`
	display: block;
	${mobile}{
		display: none;
	}
`
const ImageContainer = styled.div`
	padding-left: var(--l);
	grid-column: span 4;
	${mobile}{
		padding: 0;
		grid-column: span 10;
	}
`
const MemberImage = styled(Image)`
	margin-bottom: var(--xs);
	${mobile}{
		margin-bottom: var(--m);
	}
`
const Name = styled.h4`
	margin-bottom: var(--xxxs);
`
const Role = styled.div`
	color: var(--teal);
	margin-bottom: var(--m);
`
const MemberText = styled.div`
	${mobile}{
		margin-bottom: var(--m);
	}
`
const Content = styled.div`
	grid-column: 6/11;
	padding-right: var(--l);
	${mobile}{
		grid-column: span 10;
	}
`
const LinkedInButton = styled(Button)`
	${mobile}{
		display: none;
	}
`
const LinkedInButtonMobile = styled(LinkedInButton)`
	display: none;
	${mobile}{
		display: block;
	}
`

const Team = ({ className, content }) => {
	return (
		<Wrap className={className}>
			<Heading>{content.title}</Heading>
			<Text>
				<RichText content={content.text}/>
			</Text>
			<Members>
				{content.members?.map(member => (
					<Member member={member} key={member.name} />
				) )}
			</Members>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	background-color: var(--beige60);
	padding: 100px 0;
	:not(:last-of-type){
		padding-top: 0;
	}
	${mobile}{
		margin: 64px 0;
	}
`
const Heading = styled.h3`
	margin-bottom: var(--m);
	grid-column: 1/13;
	text-align: center;
`
const Text = styled.div`
	margin-bottom: var(--xxxl);
	grid-column: 3/11;
	text-align: center;
`
const Members = styled.div`
	grid-column: 2/12;
	display: grid;
	grid-row-gap: var(--l);
	${tablet}{
		grid-column: span 12;
	}
`
Team.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

Member.propTypes = {
	member: PropTypes.object,
}

export default Team