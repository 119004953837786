import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '@styles/global'
import Section from '@components/Section'
import Image from '@components/Image'
import { BdrRarr, FillRarr } from '@components/Svg'
import SanityLink from '@components/SanityLink'

const QuickLink = ({ className, content }) => {
	
	const [hover, setHover] = useState(false)

	return (
		<QuickLinkWrap
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			css={css`
				filter: ${hover ? 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.5))' : 'drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.5))'};
			`}
		>
			<LinkContainer link={content.link}>
				<MainImage image={content.image} aspectRatio={0.991}/>
				<Bottom>
					<LinkTitle>
						{content.title}
					</LinkTitle>
					<ArrowContainer>
						<Arrow css={css`opacity: ${hover ? '0' : '1'};`}/>
						<HoverArrow css={css`opacity: ${hover ? '1' : '0'};`}/>
					</ArrowContainer>
				</Bottom>
			</LinkContainer>
		</QuickLinkWrap>
	)
}

const QuickLinkWrap = styled.div`
	grid-column: span 3;
	:first-of-type{
		background-color: var(--bteal20);
	}
	:nth-of-type(2){
		background-color: var(--beige);
	}
	:nth-of-type(3){
		background-color: var(--orange60);
	}
	:last-of-type{
		background-color: var(--bteal60);
	}
	transition: filter 0.2s;
	${tablet}{
		grid-column: span 6;
	}
`
const LinkContainer = styled(SanityLink)`
	padding: var(--xxs) var(--xxs) 0;
	display: block;
	${mobile}{
		padding: var(--xxxs) var(--xxxs) 0;
	}
`
const MainImage = styled(Image)`
	margin-bottom: var(--xxs);
`
const Bottom = styled.div`
	min-height: 58px;
	display: flex;
	padding: 0 0 var(--xxs) 0;
	box-sizing: border-box;
	justify-content: space-between;
`
const LinkTitle = styled.h4`
  overflow-wrap: break-word;
  word-wrap: break-word;
	hyphens: auto;
	width: calc(100% - 44px);
	${mobile}{
		font-size: 20px;
    line-height: 26px;
		letter-spacing: 0.02em;
	}
`
const ArrowContainer = styled.div`
	width: 34px;
	height: 34px;
	position: relative;
	margin-top: 3px;
`
const Arrow = styled(BdrRarr)`
	width: 34px;
	transition: opacity 0.2s;
`
const HoverArrow = styled(FillRarr)`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	transition: opacity 0.2s;
`

const QuickLinks = ({ className, content, id }) => {

	return (
		<Wrap className={className} id={id}>
			<Title>{content.title}</Title>
			{content?.links?.map(link => (
				<QuickLink key={link.title} content={link}/>
			))}
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: var(--xxl) 0;
	> div {
		grid-row-gap: 30px
	}
	${mobile}{
		margin: 64px 0;
		> div {
			grid-row-gap: 14px
		}
	}
`
const Title = styled.h3`
	grid-column: span 12;
	margin-bottom: var(--xl);
	${mobile}{
		margin-bottom: var(--l);
	}
`

QuickLink.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

QuickLinks.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	id: PropTypes.string,
}

export default QuickLinks