import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '@styles/global'
import { FillDarr, Corner } from '@components/Svg'

const File = ({ className, content, bgColor }) => {

	const dogEarColor = () => {
		switch (content?.background?.value) {
		case '#E1F9F7':
			return 'var(--bteal40)'
		case '#FFFFFF': 
			return 'var(--beige)'
		case '#D7D2CE':
			return 'var(--xdbeige)'
		case '#FFE4C9':
			return 'var(--peach)'
		}
	}

	return (
		<Wrap 
			className={className} 
			href={content?.file?.asset?.url ?? ''}
			target="_blank"
			download 
			color={content?.background?.value} 
			dogEar={dogEarColor()}
			bgColor={bgColor}
			css={css`
				background: ${`linear-gradient(225deg, ${bgColor} 28.5px, ${content?.background?.value} 0)`};
		`}>
			<Title className='para'>
				{content.title}
			</Title>
			<Baseline>
				<Cta>{content.cta}</Cta>
				<Icon></Icon>
			</Baseline>
		</Wrap>
	)
}
const Wrap = styled.a`
	display: block;
	min-height: 340px;
	position: relative;
	padding: var(--m) var(--s);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;
	:before{
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		border-style: solid;
		background-color: ${props => props.dogEar};
		border: none;
		display: block;
		width: 40px;
		height: 40px;
		clip-path: path('M0 0L1466 1466L0 1466L0 0Z');
	}
`

const Title = styled.div`
	
`
const Baseline = styled.div`
	display: flex;
	justify-content: space-between; 
	align-items: center;
`
const Cta = styled.h5`
	
`
const Icon = styled(FillDarr)`
	width: 34px;
	height: 34px;
`

File.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	bgColor: PropTypes.string,
}

export default File