import useModuleSettings from '@utils/useModuleSettings'
import { paramCase } from 'change-case'

const useGenerateJumpLinks = modules => modules?.filter(module => module?.include).map(module => {
	const {helpLine, smartSearch, checklist} = useModuleSettings()
	let title
	if (['quickLinks', 'logoGrid', 'faqBlock', 'fileCollection', 'contactForm', 'formAssembyEmbed', 'textColumns'].includes(module._type)) {
		title = module?.title || module?.headline
	} else if (module?._type === 'helpLine'){
		title = helpLine?.eyebrow
	} else if (module?._type === 'smartSearch'){
		title = smartSearch?.eyebrow
	} else if (module?._type === 'checklist'){
		title = checklist?.eyebrow
	} else if (module?.eyebrow) {
		title = module?.eyebrow
	}
	return { 
		key: module?._key, 
		title, 
		hash: title ? paramCase(title) : ''
	}
})

export default useGenerateJumpLinks