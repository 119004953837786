import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, desktop, Eyebrow } from '@styles/global'
import RichText from '@components/RichText'
import Section from '@components/Section'
import ModuleStyles from '@styles/ModuleStyles'
const FreeText = ({ className, content }) => {
	return (
		<Wrap className={className} bgColor={content.background?.value}>
			<Container>
				<Eyebrow>{content.eyebrow}</Eyebrow>
				<ModuleStyles>
					<RichText moduleStyles content={content.text}/>
				</ModuleStyles>
			</Container>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding: var(--xxxl) 0 var(--xl);
	background-color: ${props => props.bgColor ? props.bgColor : 'var(--white)'};
`
const Container = styled.div`
	grid-column: span 8;
	${desktop}{
		grid-column: span 10;
	}
	${tablet}{
		grid-column: span 12;
	}
`

FreeText.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default FreeText