import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, desktop } from '@styles/global'
import Section from '@components/Section'
import Image from '@components/Image'
import RichText from '@components/RichText'
import SanityLink from '@components/SanityLink'

const LogoGrid = ({ className, content }) => {
	return (
		<Wrap className={className}>
			<Section>
				<Heading>{content?.title}</Heading>
				<Intro>
					<RichText content={content?.text}/>
				</Intro>
			</Section>
			<Section>
				{content?.logos?.map(logo => (
					<Logo 
						key={logo.title}
						link={logo.link}
					>
						<LogoImage image={logo.image}/>
					</Logo>
				))}
			</Section>
		</Wrap>
	)
}

const Wrap = styled.div`
	padding: var(--l) 0;
	${mobile}{
		padding: var(--m) 0;
	}
`
const Heading = styled.h3`
	grid-column: 1/13;
	text-align: center;
	margin-bottom: var(--s);
`
const Intro = styled.div`
	grid-column: 4/10;
	margin-bottom: var(--l);
	text-align: center;
	${desktop}{
		grid-column: 2/12;
	}
	${mobile}{
		grid-column: span 12;
	}
`
const Logo = styled(SanityLink)`
	grid-column: span 3;
	border: 1px solid var(--dbeige);
	margin-bottom: var(--s);
	${tablet}{
		grid-column: span 6;
	}
`
const LogoImage = styled(Image)`
	
`
LogoGrid.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default LogoGrid